export const DESK_TIME_API_KEY = "134b5f38a136b02a940a845642f265e4";

const API = {
    Auth: {
        Login: {
            api: "/auth/login",
        }, RefreshToken: {
            api: "/auth/refresh_token",
        },
    },
    GlobalNotification: {
        api : "/student_portal/global_notification"
    },
    Log: {
        flywire_failed_payment: {
            api : "/logs/flywire_failed_payment"
        },
        flywire_cancel_payment: {
            api: "/logs/flywire_cancel_payment"
        }
    },
    User: {
        Get: {
            api: "/student_portal/profile",
        }, Put: {
            api: "/student_portal/profile",
        },
    },
    Placement: {
        Get: {
            api: '/student_portal/placement'
        }
    },
    RecordedPayments: {
        Get: {
            api: '/student_portal/recorded_payments'
        }
    },
    Accommodation: {
        Get: {
            api: '/student_portal/accommodation'
        }
    },
    Installment: {
        Get: {
            api: '/student_portal/installment'
        }
    },
    Service: {
        Get: {
            api: '/student_portal/service'
        }
    },
    Agent: {
        Get: {
            api: '/student_portal/agent'
        }
    },
    Invoices: {
        Get: {
            api: '/student_portal/invoices'
        }
    },
    AdditionalServices: {
        StorageServices: {
            Types: {
                Get: {
                    api: '/storage_service/types'
                }
            },
            Address: {
                Post: {
                    api: "/storage_service/address",
                },
                Get: {
                    api: '/storage_service/address'
                }
            },
            CheckOut: {
                Get: {
                    api: '/storage_service/checkout',
                    create: (paymentType, invoiceId, country_code, payment_method, payer_document, student_chinese_name, state) => {
                        if (paymentType !== "transfermate") {
                            return API.AdditionalServices.StorageServices.CheckOut.Get.api + `/${paymentType}` + `?invoice_id=${invoiceId}`;
                        } else {
                            if(payment_method) {
                                return API.AdditionalServices.StorageServices.CheckOut.Get.api + `/${paymentType}` + `?invoice_id=${invoiceId}&country_code=${country_code}&payment_method=${payment_method}&payer_document=${payer_document}&student_chinese_name=${student_chinese_name}&state=${state}`;
                            }
                        }
                        return ""; // `paymentType` "transfermate" ise boş string döndür.
                    }                },
                Post: {
                    api: "/storage_service/checkout"
                }
            }
        },
        Get: {
            api: '/student_portal/additional_services',
            create: (invoiceId, isProduct, categoryId) => invoiceId ? API.AdditionalServices.Get.api + `?invoice_id=${invoiceId}&type=${isProduct ? "1" : "0"}` + (categoryId ? `&category_id=${categoryId}` : "") : ""
        }
    },
    Order: {
        Get: {
            api: '/student_portal/orders',
        }
    },
    Letters: {
        Get: {
            api: '/student_portal/my_documents?type=letter',
        }
    },
    Payment: {
        Get: {
            api: '/student_portal/payment',
        },
        Checkout: {
            POST: '/payment/checkout',
        },
        Flywire: {
            POST: '/student_portal/flywire/payment_callback',
            Notification : {
                POST: '/student_portal/flywire/notification'
            }
        }
    },
    News: {
        GetAll: {
            api: '/news'
        }, Get: {
            api: '/news', create: (newsSlug) => `/news/${newsSlug}`
        }
    },
    Offer: {
        GetAll: {
            api: '/promotions',
        }, Get: {
            api: '/promotions', create: (promotionsSlug) => `/promotions/${promotionsSlug}`
        }
    },
    Categories: {
        Get: {
            api: "/student_portal/categories",
            create: (activeTab) => "/student_portal/categories" + `?parent=${activeTab === "products" ? "shop" : "service"}`,
        }
    },
    Request: {
        All: {
            Get: {
                api: "/student_portal/student_requests"
            }
        },
        Requests: {
            Get: {
                api: "/student_portal/student_request",
                create: (requestId) => requestId ? API.Request.Requests.Get.api + `/${requestId}` : ""
            }
        }, Request: {
            Get: {
                api: "/student_portal/student_requests",
                create: (requestId) => requestId ? API.Request.Request.Get.api + `?id=${requestId}` : ""
            }, Post: {
                api: "/student_portal/student_request",
            },
        },
        RequestMessage: {
            Post: {
                api: "/student_portal/student_request_message"
            }
        }
    },
    Airport: {
        GetList: {
            Get: {
                api: "/student_portal/airports"
            }
        },
        Cars: {
            Get: {
                api: "/student_portal/airport_cars",
                create: (airportId, personCount) => airportId ? API.Airport.Cars.Get.api + `/${airportId}?person=${personCount}` : ""
            }
        }
    },
    Ingresso: {
        Events: {
            Get: {
                api: "/ingresso/events",
                create: (eventId) => eventId ? API.Ingresso.Events.Get.api + `/${eventId}` : ""
            }
        }
    },
    Wallet: {
            Get: {
                api: "/ambassador/wallet",
            }
    },
    Ambassador: {
        Post: {
            api: "/ambassador/payment",
        }
    },
    TransferMate: {
        Countries: {
            Get: {
                api: "/payment/transfermate/countries"
            }
        },
        Conversion: {
            Get: {
                api: "/payment/transfermate/conversions",
                create: (country_code, amount) => country_code ? API.TransferMate.Conversion.Get.api + `?country_code=${country_code}&amount=${amount}` : ""
            }
        }
    }
};
export default API;

export function getBaseApi(url) {
    return (!url.startsWith("https://") ? process.env.REACT_APP_BASE_API : "") + url;
}

